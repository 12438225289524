var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      class: _vm.$vuetify.breakpoint.xs ? "pa-3" : "px-7 py-3",
      attrs: { height: "100%", loading: _vm.loading }
    },
    [
      _vm.showPainCoachDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "content-class": "rounded-xl", "max-width": "1200" },
              model: {
                value: _vm.showPainCoachDialog,
                callback: function($$v) {
                  _vm.showPainCoachDialog = $$v
                },
                expression: "showPainCoachDialog"
              }
            },
            [
              _c("pain-coach-dialog", {
                attrs: { painArea: _vm.painArea },
                model: {
                  value: _vm.showPainCoachDialog,
                  callback: function($$v) {
                    _vm.showPainCoachDialog = $$v
                  },
                  expression: "showPainCoachDialog"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        {
          staticClass: "fill-height",
          attrs: { justify: "center", "no-gutters": "" }
        },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c(
              "p",
              {
                staticClass: "text-h6 text-center",
                attrs: { id: "painGraphTitle" }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "deskAssessment.results.painContent.painComparisonGraph.title",
                        {
                          0: _vm.$t(
                            "wellness.v2.userProgrammeNames." + _vm.painArea
                          )
                        }
                      )
                    ) +
                    " "
                )
              ]
            ),
            _c(
              "p",
              { staticClass: "text-body-1 my-6" },
              [
                _c("i18n", {
                  attrs: { path: _vm.explanationSentencePath },
                  scopedSlots: _vm._u([
                    {
                      key: "percentageDifference",
                      fn: function() {
                        return [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(_vm._s(_vm.majorityPercentage) + "%")
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ],
              1
            )
          ]),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                {
                  staticClass: "parent mt-4",
                  style: "min-height:" + _vm.graphMaxHeight + "px",
                  attrs: { align: "end", justify: "center" }
                },
                [
                  _c(
                    "p",
                    { staticClass: "text-caption text-center mb-0 yLabel" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "deskAssessment.results.painContent.painComparisonGraph.axisLabels.y"
                            )
                          ) +
                          " "
                      )
                    ]
                  ),
                  _vm._l(_vm.populationVASCounts, function(vas) {
                    return _c(
                      "v-col",
                      {
                        key: vas.level,
                        staticClass: "px-1 mt-5",
                        staticStyle: { position: "relative" },
                        attrs: { cols: "1" }
                      },
                      [
                        vas.level === _vm.vasLevel
                          ? _c(
                              "div",
                              {
                                staticClass: "text-center",
                                staticStyle: {
                                  position: "absolute",
                                  left: "50%",
                                  top: "0",
                                  transform: "translate(-50%, -100%)"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { "margin-bottom": "-10px" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "deskAssessment.results.painContent.painComparisonGraph.you"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c("div", [_vm._v("▼")])
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "parent",
                            style:
                              "height:" +
                              _vm.graphMaxHeight * (vas.count / _vm.maxCount) +
                              "px;",
                            attrs: { id: "parent" + vas.level }
                          },
                          [
                            _c("div", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.loading,
                                  expression: "!loading"
                                }
                              ],
                              staticClass: "bar rounded-pill",
                              class: _vm.getBarClass(vas),
                              attrs: {
                                "data-inviewport": _vm.switchedArea
                                  ? ""
                                  : "fill-height",
                                id: "bar" + vas.level
                              }
                            })
                          ]
                        ),
                        _c("v-img", {
                          staticClass: "greyScale mt-2",
                          attrs: {
                            src: require("@/assets/images/emojis/painLevel" +
                              vas.level +
                              ".svg"),
                            eager: "",
                            contain: "",
                            "max-height": "20px"
                          }
                        }),
                        vas.level === 1
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "text-center mb-0 painDescriptor text-caption"
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.painDescriptors[0]) + " "
                                )
                              ]
                            )
                          : vas.level === 10
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "text-center mb-0 painDescriptor text-caption"
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.painDescriptors[1]) + " "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  })
                ],
                2
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0 my-5", attrs: { cols: "12" } },
                    [
                      _c(
                        "p",
                        { staticClass: "text-caption text-center mb-0" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "deskAssessment.results.painContent.painComparisonGraph.axisLabels.x"
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "my-4", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "trial white--text buttonBackground",
                                  attrs: {
                                    "max-width": "300px",
                                    rounded: "",
                                    small: ""
                                  },
                                  on: { click: _vm.openPainCoachDialog }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "deskAssessment.results.painContent.painComparisonGraph.howWeCanHelpYou"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import PainFactorAreas from "@/assets/json/DeskAssessment/PainFactorAreas.json";
import criteriaChecker from "@/components/common/criteria-checker.js";
import PainFactorIcons from "@/assets/json/DeskAssessment/PainFactorIcons.json";
import { getRankedPainAreas } from "@/services/deskassessment/desk-assessment-summary";
import PainAreas from "@/components/painareas/PainAreasMale.json";

let healthQuestionsToDetermineIfLifestyleActive = [
  "gender",
  "pregnancy",
  "smoker",
  "bmi"
];

class DisplayedIcon {
  constructor(element) {
    (this.id = element.icon),
      (this.icon = PainFactorIcons[element.icon]),
      (this.text = element.text),
      (this.disabled = element.disabled);
  }
}

function flattenResults(results, area) {
  return { ...results.pain.areas[area], ...results.health };
}

function getLifestyleIcon(results) {
  let healthSectionAnswered = Object.keys(results.health).some(
    r => healthQuestionsToDetermineIfLifestyleActive.indexOf(r) >= 0
  );
  let iconId = healthSectionAnswered
    ? "lifestyleAnswered"
    : "lifestyleDisabled";

  return {
    id: iconId,
    icon: PainFactorIcons[iconId],
    text: healthSectionAnswered ? getPainFactorText(results) : undefined,
    disabled: !healthSectionAnswered
  };
}

function getPainFactorText(results) {
  var focusedArea = getRankedPainAreas(results)[0];
  let unhealthyBmi =
    results.health.bmi !== undefined &&
    results.health.bmi !== null &&
    (results.health.bmi >= 25 || results.health.bmi <= 18.5);
  let smoker = results.health.smoker === 1;
  let notEnoughExercise =
    results.health.exercise !== undefined &&
    results.health.exercise !== null &&
    results.health.exercise < 3;

  let lifestyleText = [];
  if (notEnoughExercise) {
    lifestyleText.push({
      mainText:
        "deskAssessment.results.painContent.importantFactors.hoverText.lifestyle.exercise"
    });
  }

  if (smoker || unhealthyBmi) {
    let specificText = "";
    if (smoker && unhealthyBmi) {
      specificText =
        "deskAssessment.results.painContent.importantFactors.hoverText.lifestyle.lifestyleFactors.bmiAndSmoking";
    } else if (smoker) {
      specificText =
        "deskAssessment.results.painContent.importantFactors.hoverText.lifestyle.lifestyleFactors.smoking";
    } else {
      specificText =
        "deskAssessment.results.painContent.importantFactors.hoverText.lifestyle.lifestyleFactors.bmi";
    }

    lifestyleText.push({
      mainText:
        "deskAssessment.results.painContent.importantFactors.hoverText.lifestyle.generic",
      extraText: [
        { id: "lifestyleFactor", text: specificText },
        {
          id: "painArea",
          text: `reportText.pain.painAreaNames.${focusedArea.area}.title`
        }
      ]
    });
  }
  return lifestyleText;
}

export function getPainFactors(results, area) {
  let factors = [];
  let flatResults = flattenResults(results, area);
  let group = PainAreas[area].group;
  PainFactorAreas[group].forEach(element => {
    let match = criteriaChecker.areCriteriaMet(flatResults, element.criteria);
    if (match) {
      factors.push(new DisplayedIcon(element));
    }
  });

  let lifeStyleIcon = getLifestyleIcon(results);
  if (lifeStyleIcon.disabled || lifeStyleIcon.text.length > 0) {
    factors.push(lifeStyleIcon);
  }
  return factors;
}

<template>
  <vitrue-dialog
    :title="
      $vuetify.breakpoint.xs
        ? $t(
            'deskAssessment.results.painContent.painComparisonGraph.dialog.title.short'
          )
        : $t(
            'deskAssessment.results.painContent.painComparisonGraph.dialog.title.long'
          )
    "
    @input="$emit('input', $event)"
  >
    <v-row
      class="rounded-xl"
      :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-12'"
      align="center"
      no-gutters
      :style="containerBackgroundImageStyle"
    >
      <v-col cols="12" lg="4">
        <v-row no-gutters justify="center">
          <v-col cols="auto">
            <v-img
              src="https://vida-wellness-assets.s3.eu-west-2.amazonaws.com/neckDay1SingleLoop.gif"
              :lazy-src="require(`@/assets/images/wellness/neck/neckDay1.jpg`)"
              class="rounded-xl painCoachImage"
              :height="$vuetify.breakpoint.smAndDown ? '200px' : '400px'"
              :width="$vuetify.breakpoint.smAndDown ? '200px' : '350px'"
            >
            </v-img>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        lg="8"
        :class="$vuetify.breakpoint.lgAndUp ? 'pl-10' : ''"
      >
        <p
          class="py-3 px-2"
          :class="$vuetify.breakpoint.xs ? 'text-subtitle-1' : 'text-h6'"
        >
          {{
            $t(
              "deskAssessment.results.painContent.painComparisonGraph.dialog.explanation"
            )
          }}
        </p>
        <v-row
          v-for="(goal, index) in $t(
            `deskAssessment.results.painContent.painComparisonGraph.dialog.goals.${painArea}`
          )"
          :key="goal.title"
          no-gutters
        >
          <v-col class="text-h5 font-weight-bold" cols="2" sm="1">
            0{{ index + 1 }}
          </v-col>
          <v-col cols="10" sm="11">
            <p
              class="font-weight-bold"
              :class="$vuetify.breakpoint.xs ? 'text-subtitle-1' : 'text-h6'"
            >
              {{ goal.title }}
            </p>
            <p class="text-body-1">{{ goal.text }}</p>
          </v-col>
        </v-row>
        <v-row justify="center" align="end"
          ><v-col cols="auto">
            <v-btn
              max-width="300px"
              rounded
              class="trial white--text font-weight-bold"
              style="
                background-image: linear-gradient(to right, #8d67f9, #24c3f9);
              "
              @click="openWellnessInNewTab()"
              >{{
                $t(
                  "deskAssessment.results.painContent.painComparisonGraph.dialog.tryItNow"
                )
              }}</v-btn
            ></v-col
          >
        </v-row>
      </v-col>
    </v-row>
  </vitrue-dialog>
</template>

<script>
import VitrueDialog from "@/components/common/VitrueDialog.vue";
import PlayButton from "@/components/common/animations/PlayButton";

export default {
  name: "PainCoachDialog",
  components: {
    VitrueDialog,
    PlayButton
  },
  props: {
    painArea: String
  },
  computed: {
    containerBackgroundImageStyle() {
      return {
        backgroundImage: `url(${require("@/assets/images/curveBackground.png")})`,
        backgroundSize: "cover"
      };
    }
  },
  methods: {
    openWellnessInNewTab() {
      this.$mixpanel.track(`Gone to pain coach from pain content dialog`);
      let routeData = this.$router.resolve({
        name: "Wellness"
      });
      window.open(routeData.href, "_blank");
    }
  }
};
</script>

<style scoped>
.painCoachImage {
  border: 10px solid var(--v-primary-base);
  position: relative;
}
</style>

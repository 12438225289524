var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "fill-height px-5 py-3", attrs: { rounded: "lg" } },
    [
      _c(
        "v-row",
        {
          staticClass: "fill-height",
          attrs: {
            "align-content": "stretch",
            justify: "center",
            "no-gutters": ""
          }
        },
        [
          _c(
            "v-col",
            {
              staticClass: "text-h6 justify-center pa-0 text-center",
              attrs: { cols: "12", id: "title", "align-self": "start" }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "deskAssessment.results.painContent.importantFactors.title",
                      { painArea: _vm.painAreaText }
                    )
                  ) +
                  " "
              )
            ]
          ),
          _vm.showIconView
            ? _c(
                "v-col",
                {
                  staticClass: "py-3 text-body-2",
                  class: _vm.viewingOwnResults ? "" : "blur",
                  attrs: {
                    id: "iconContainer",
                    cols: "12",
                    "align-self": "stretch"
                  }
                },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    _vm._l(_vm.painFactors, function(factor, index) {
                      return _c(
                        "v-col",
                        {
                          key: factor.id,
                          staticClass: "text-center pa-5",
                          class: _vm.hoverable(factor) ? "hoverOver" : "",
                          attrs: { cols: "4", id: "hoverIcon" + index },
                          on: {
                            mouseover: function($event) {
                              return _vm.hoverOverItem(factor)
                            },
                            click: function($event) {
                              return _vm.hoverOverItem(factor)
                            },
                            mouseleave: function($event) {
                              _vm.hoveredItem = null
                            }
                          }
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "pb-2",
                              attrs: { justify: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      id: "hoverIconImage" + index,
                                      src: require("@/assets/images/icons/painContent/" +
                                        factor.icon.image),
                                      height: "50px",
                                      width: "50px"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" " + _vm._s(_vm.$t(factor.icon.title)) + " ")
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.showIconView && _vm.viewingOwnResults
            ? _c(
                "v-col",
                {
                  staticClass: "text-body-2 vitrueDarkGrey--text px-2",
                  class: _vm.viewingOwnResults ? "" : "blur",
                  staticStyle: { "min-height": "250px" },
                  attrs: { cols: "12", id: "itemDescription" }
                },
                _vm._l(_vm.descriptions, function(
                  description,
                  descriptionIndex
                ) {
                  return _c(
                    "p",
                    { key: descriptionIndex },
                    [
                      _c(
                        "i18n",
                        { attrs: { path: description.mainText } },
                        [
                          _vm._l(description.extraText, function(extra, index) {
                            return _c("template", { slot: extra.id }, [
                              _c(
                                "span",
                                {
                                  key: descriptionIndex + extra.id + index,
                                  class: extra.bold ? "font-weight-bold" : "",
                                  staticStyle: { "text-transform": "lowercase" }
                                },
                                [_vm._v(_vm._s(_vm.$t(extra.text)))]
                              )
                            ])
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          !_vm.viewingOwnResults
            ? _c(
                "v-col",
                {
                  staticClass:
                    "text-caption primary--text notAuthorizedContainer pa-2",
                  attrs: {
                    cols: "12",
                    id: "notViewingOwnResults",
                    "align-self": "end"
                  }
                },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "start", "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "px-2", attrs: { cols: "auto" } },
                        [
                          _c("div", {
                            staticClass: "vertical-bar divider",
                            staticStyle: { width: "3px" }
                          })
                        ]
                      ),
                      _c("v-col", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "deskAssessment.results.painContent.importantFactors.notViewingOwnResults"
                            )
                          )
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.showAbsSpecialCase || _vm.showRedFlags
            ? _c(
                "v-col",
                {
                  staticClass: "text-body-2",
                  class: _vm.viewingOwnResults ? "" : "blur",
                  attrs: { id: "specialCasedContainer", cols: "10" }
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "py-4", attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-img", {
                            attrs: {
                              src: _vm.showRedFlags
                                ? require("@/assets/images/icons/painContent/redFlags.svg")
                                : require("@/assets/images/icons/painContent/orangeWarning.svg"),
                              height: "100px",
                              width: "100px"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("p", { attrs: { id: "specialCaseText1" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.showRedFlags
                            ? _vm.$t(
                                "deskAssessment.results.painContent.importantFactors.redFlag.1",
                                { painArea: _vm.painAreaText }
                              )
                            : _vm.$t(
                                "deskAssessment.results.painContent.importantFactors.hoverText.abs.1"
                              )
                        ) +
                        " "
                    )
                  ]),
                  _c("p", { attrs: { id: "specialCaseText2" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.showRedFlags
                            ? _vm.$t(
                                "deskAssessment.results.painContent.importantFactors.redFlag.2",
                                { painArea: _vm.painAreaText }
                              )
                            : _vm.$t(
                                "deskAssessment.results.painContent.importantFactors.hoverText.abs.2"
                              )
                        ) +
                        " "
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-row>
    <v-col cols="12" md="6" lg="4"
      ><pain-details
        :results="results"
        :painArea="currentPainArea"
        @painAreaSelected="currentPainArea = $event"
    /></v-col>
    <v-col cols="12" md="6" lg="4"
      ><important-factors
        :results="results"
        :painArea="currentPainArea"
        :painAreaGroup="currentPainAreaGroup"
    /></v-col>
    <v-col cols="12" md="6" lg="4">
      <pain-comparison-graph-vue
        :vasLevel="currentPainLevel"
        :painArea="currentPainAreaGroup"
      />
    </v-col>
  </v-row>
</template>

<script>
import DeskAssessmentUtil from "@/services/deskassessment/desk-assessment-results-util";
import * as Constants from "@/constants/constants.js";
import ImportantFactors from "@/components/deskassessment/report/content/pain/ImportantFactors.vue";
import PainDetails from "@/components/deskassessment/report/content/pain/PainDetails.vue";
import PainComparisonGraphVue from "@/components/deskassessment/report/content/pain/PainComparisonGraph.vue";
import PainAreas from "@/components/painareas/PainAreasMale.json";
import { getRankedPainAreas } from "@/services/deskassessment/desk-assessment-summary";

export default {
  name: "PainPanel",
  props: {
    results: {},
    showPregnancyContent: Boolean
  },
  components: {
    ImportantFactors,
    PainComparisonGraphVue,
    PainDetails
  },
  data() {
    return { painArea: null };
  },
  computed: {
    painAreas() {
      return this.results.pain.areas;
    },
    currentPainArea: {
      get() {
        if (!this.painArea) {
          let redFlagArea = this.getFirstAreaWithRedFlags();

          return redFlagArea
            ? redFlagArea
            : getRankedPainAreas(this.results)[0].area;
        }
        return this.painArea;
      },
      set(val) {
        this.painArea = val;
      }
    },
    currentPainAreaGroup() {
      return PainAreas[this.currentPainArea].group;
    },
    currentPainLevel() {
      return this.painAreas[this.currentPainArea].level;
    }
  },
  methods: {
    getFirstAreaWithRedFlags() {
      let redFlagArea = "";
      Object.keys(this.results.pain.areas).forEach(area => {
        let hasRedFlag = DeskAssessmentUtil.hasRedFlags(
          area,
          this.results.pain.areas[area],
          Constants.assessmentTypes.deskAssessment
        );
        if (hasRedFlag && !redFlagArea) {
          redFlagArea = area;
        }
      });
      return redFlagArea;
    }
  }
};
</script>

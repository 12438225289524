<template>
  <v-card
    :class="$vuetify.breakpoint.xs ? 'pa-3' : 'px-7 py-3'"
    height="100%"
    :loading="loading"
  >
    <v-dialog
      content-class="rounded-xl"
      v-if="showPainCoachDialog"
      v-model="showPainCoachDialog"
      max-width="1200"
    >
      <pain-coach-dialog v-model="showPainCoachDialog" :painArea="painArea" />
    </v-dialog>

    <v-row class="fill-height" justify="center" no-gutters>
      <v-col cols="12">
        <p id="painGraphTitle" class="text-h6 text-center">
          {{
            $t("deskAssessment.results.painContent.painComparisonGraph.title", {
              0: $t(`wellness.v2.userProgrammeNames.${painArea}`)
            })
          }}
        </p>
        <p class="text-body-1 my-6">
          <i18n :path="explanationSentencePath">
            <template v-slot:percentageDifference>
              <span class="font-weight-bold">{{ majorityPercentage }}%</span>
            </template>
          </i18n>
        </p>
      </v-col>
      <v-col cols="12"
        ><v-row
          align="end"
          justify="center"
          class="parent mt-4"
          :style="`min-height:${graphMaxHeight}px`"
        >
          <p class="text-caption text-center mb-0 yLabel">
            {{
              $t(
                "deskAssessment.results.painContent.painComparisonGraph.axisLabels.y"
              )
            }}
          </p>
          <v-col
            v-for="vas in populationVASCounts"
            :key="vas.level"
            cols="1"
            class="px-1 mt-5"
            style="position: relative"
          >
            <div
              v-if="vas.level === vasLevel"
              class="text-center"
              style="
                position: absolute;
                left: 50%;
                top: 0;
                transform: translate(-50%, -100%);
              "
            >
              <div style="margin-bottom: -10px">
                {{
                  $t(
                    "deskAssessment.results.painContent.painComparisonGraph.you"
                  )
                }}
              </div>
              <div>▼</div>
            </div>

            <div
              class="parent"
              :id="`parent${vas.level}`"
              :style="
                'height:' + graphMaxHeight * (vas.count / maxCount) + 'px;'
              "
            >
              <div
                v-show="!loading"
                :data-inviewport="switchedArea ? '' : 'fill-height'"
                class="bar rounded-pill"
                :id="`bar${vas.level}`"
                :class="getBarClass(vas)"
              />
            </div>
            <v-img
              :src="require(`@/assets/images/emojis/painLevel${vas.level}.svg`)"
              eager
              contain
              max-height="20px"
              class="greyScale mt-2"
            />
            <p
              v-if="vas.level === 1"
              class="text-center mb-0 painDescriptor text-caption"
            >
              {{ painDescriptors[0] }}
            </p>
            <p
              v-else-if="vas.level === 10"
              class="text-center mb-0 painDescriptor text-caption"
            >
              {{ painDescriptors[1] }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-0 my-5">
            <p class="text-caption text-center mb-0">
              {{
                $t(
                  "deskAssessment.results.painContent.painComparisonGraph.axisLabels.x"
                )
              }}
            </p>
          </v-col>
          <v-col cols="12" class="my-4">
            <v-row justify="center">
              <v-col cols="auto">
                <v-btn
                  max-width="300px"
                  rounded
                  class="trial white--text buttonBackground"
                  small
                  @click="openPainCoachDialog"
                >
                  {{
                    $t(
                      "deskAssessment.results.painContent.painComparisonGraph.howWeCanHelpYou"
                    )
                  }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row></v-col
      >
    </v-row>
  </v-card>
</template>

<script>
import { getGlobalPainLevelCounts } from "@/customApi";
import PainCoachDialog from "./PainCoachDialog.vue";
import { mapGetters } from "vuex";

export default {
  name: "PainComparisonGraph",
  props: {
    painArea: String,
    vasLevel: Number
  },
  components: {
    PainCoachDialog
  },
  data() {
    return {
      populationVASCounts: [
        { level: 1, count: 0 },
        { level: 2, count: 0 },
        { level: 3, count: 0 },
        { level: 4, count: 0 },
        { level: 5, count: 0 },
        { level: 6, count: 0 },
        { level: 7, count: 0 },
        { level: 8, count: 0 },
        { level: 9, count: 0 },
        { level: 10, count: 0 }
      ],
      showPainCoachDialog: false,
      painDescriptors: [
        this.$t(
          "deskAssessment.results.painContent.painComparisonGraph.level.mild"
        ),
        this.$t(
          "deskAssessment.results.painContent.painComparisonGraph.level.unimaginable"
        )
      ],
      switchedArea: false,
      loading: false
    };
  },
  async mounted() {
    this.setupAnimation();
    await this.getPainCounts();
  },
  watch: {
    async painArea() {
      this.switchedArea = true;
      this.$nextTick(this.getPainCounts);
    },
    signedIn() {
      this.getPainCounts();
    }
  },
  computed: {
    ...mapGetters(["signedIn"]),
    maxCount() {
      return Math.max(...this.populationVASCounts.map(x => x.count));
    },
    graphMaxHeight() {
      return this.$vuetify.breakpoint.xl ? 250 : 200;
    },
    percentageMoreSevere() {
      var totalCount = this.sumCounts(this.populationVASCounts);

      if (totalCount === 0) {
        return 0;
      }

      var usersWithMorePain = this.populationVASCounts.filter(
        x => x.level > this.vasLevel
      );
      var usersWithMorePainCount = this.sumCounts(usersWithMorePain);
      return Math.round((usersWithMorePainCount / totalCount) * 100);
    },
    majorityPercentage() {
      var currentLevel = this.populationVASCounts.find(
        x => x.level === this.vasLevel
      );
      var totalCount = this.sumCounts(this.populationVASCounts);
      var currentLevelPercentage = (currentLevel.count / totalCount) * 100;

      return this.percentageMoreSevere < 50
        ? 100 - (this.percentageMoreSevere + Math.round(currentLevelPercentage))
        : this.percentageMoreSevere;
    },
    explanationSentencePath() {
      var base = "deskAssessment.results.painContent.painComparisonGraph";
      if (this.percentageMoreSevere === 0) {
        return `${base}.painAtHighestLevel`;
      }
      return this.percentageMoreSevere < 50
        ? `${base}.painInUpperHalf`
        : `${base}.painInLowerHalf`;
    }
  },
  methods: {
    async getPainCounts() {
      if (!this.signedIn) {
        return;
      }
      try {
        this.loading = true;
        let populationVASCounts = await getGlobalPainLevelCounts(this.painArea);
        this.populationVASCounts = populationVASCounts;
      } catch (err) {
      } finally {
        this.loading = false;
      }
    },
    setupAnimation() {
      const inViewport = (entries, observer) => {
        entries.forEach(entry => {
          entry.target.classList.toggle("is-inViewport", entry.isIntersecting);
        });
      };

      const Obs = new IntersectionObserver(inViewport);

      // Attach observer to every [data-inviewport] element:
      const elementsToObserve = document.querySelectorAll("[data-inviewport]");
      elementsToObserve.forEach(EL => {
        Obs.observe(EL, {});
      });
    },
    getBarClass(vas) {
      var color = vas.count > 0 ? "barWithCount" : "barWithNoCount";
      if (vas.level === this.vasLevel) {
        color = "sectionMatch";
      }
      var setHeight = this.switchedArea ? "setHeight" : "";
      return `${color} ${setHeight}`;
    },
    sumCounts(vasLevels) {
      return vasLevels.reduce((sum, a) => sum + a.count, 0);
    },
    openPainCoachDialog() {
      this.showPainCoachDialog = true;
      this.$mixpanel.track(`Pain content clicked on dialog`);
    }
  }
};
</script>

<style scoped>
.bar {
  height: 20px;
  min-height: 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

[data-inviewport="fill-height"] {
  transition: height 1s ease-in-out;
}
[data-inviewport="fill-height"].is-inViewport {
  height: 100%;
}

.setHeight {
  height: 100%;
}

.sectionMatch {
  background-image: linear-gradient(#f9a62a, #ff9900);
}

.barWithCount {
  background-image: linear-gradient(#8d67f9, #24c3f9);
}

.barWithNoCount {
  background-color: var(--v-disabled-lighten2);
}

.parent {
  position: relative;
}

.yLabel {
  position: absolute;
  bottom: 50%;
  left: 0;
  transform: rotate(270deg) translateY(-25px);
}

.painDescriptor {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 75%);
  white-space: nowrap;
}

.buttonBackground {
  background-image: linear-gradient(to right, #8d67f9, #24c3f9);
}
</style>

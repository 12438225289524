var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vitrue-dialog",
    {
      attrs: {
        title: _vm.$vuetify.breakpoint.xs
          ? _vm.$t(
              "deskAssessment.results.painContent.painComparisonGraph.dialog.title.short"
            )
          : _vm.$t(
              "deskAssessment.results.painContent.painComparisonGraph.dialog.title.long"
            )
      },
      on: {
        input: function($event) {
          return _vm.$emit("input", $event)
        }
      }
    },
    [
      _c(
        "v-row",
        {
          staticClass: "rounded-xl",
          class: _vm.$vuetify.breakpoint.smAndDown ? "pa-3" : "pa-12",
          style: _vm.containerBackgroundImageStyle,
          attrs: { align: "center", "no-gutters": "" }
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "4" } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "", justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("v-img", {
                        staticClass: "rounded-xl painCoachImage",
                        attrs: {
                          src:
                            "https://vida-wellness-assets.s3.eu-west-2.amazonaws.com/neckDay1SingleLoop.gif",
                          "lazy-src": require("@/assets/images/wellness/neck/neckDay1.jpg"),
                          height: _vm.$vuetify.breakpoint.smAndDown
                            ? "200px"
                            : "400px",
                          width: _vm.$vuetify.breakpoint.smAndDown
                            ? "200px"
                            : "350px"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              class: _vm.$vuetify.breakpoint.lgAndUp ? "pl-10" : "",
              attrs: { cols: "12", lg: "8" }
            },
            [
              _c(
                "p",
                {
                  staticClass: "py-3 px-2",
                  class: _vm.$vuetify.breakpoint.xs
                    ? "text-subtitle-1"
                    : "text-h6"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "deskAssessment.results.painContent.painComparisonGraph.dialog.explanation"
                        )
                      ) +
                      " "
                  )
                ]
              ),
              _vm._l(
                _vm.$t(
                  "deskAssessment.results.painContent.painComparisonGraph.dialog.goals." +
                    _vm.painArea
                ),
                function(goal, index) {
                  return _c(
                    "v-row",
                    { key: goal.title, attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-h5 font-weight-bold",
                          attrs: { cols: "2", sm: "1" }
                        },
                        [_vm._v(" 0" + _vm._s(index + 1) + " ")]
                      ),
                      _c("v-col", { attrs: { cols: "10", sm: "11" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "font-weight-bold",
                            class: _vm.$vuetify.breakpoint.xs
                              ? "text-subtitle-1"
                              : "text-h6"
                          },
                          [_vm._v(" " + _vm._s(goal.title) + " ")]
                        ),
                        _c("p", { staticClass: "text-body-1" }, [
                          _vm._v(_vm._s(goal.text))
                        ])
                      ])
                    ],
                    1
                  )
                }
              ),
              _c(
                "v-row",
                { attrs: { justify: "center", align: "end" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "trial white--text font-weight-bold",
                          staticStyle: {
                            "background-image":
                              "linear-gradient(to right, #8d67f9, #24c3f9)"
                          },
                          attrs: { "max-width": "300px", rounded: "" },
                          on: {
                            click: function($event) {
                              return _vm.openWellnessInNewTab()
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "deskAssessment.results.painContent.painComparisonGraph.dialog.tryItNow"
                              )
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12", md: "6", lg: "4" } },
        [
          _c("pain-details", {
            attrs: { results: _vm.results, painArea: _vm.currentPainArea },
            on: {
              painAreaSelected: function($event) {
                _vm.currentPainArea = $event
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12", md: "6", lg: "4" } },
        [
          _c("important-factors", {
            attrs: {
              results: _vm.results,
              painArea: _vm.currentPainArea,
              painAreaGroup: _vm.currentPainAreaGroup
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12", md: "6", lg: "4" } },
        [
          _c("pain-comparison-graph-vue", {
            attrs: {
              vasLevel: _vm.currentPainLevel,
              painArea: _vm.currentPainAreaGroup
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }